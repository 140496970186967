@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(../font/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'M PLUS 1';
  src: local('M PLUS 1'),
    url(../font/MPLUS1-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Rexliafree';
  src: local('Rexliafree'), url(../font/rexliafree.ttf) format('truetype');
}
body,
html {
  width: 100%;
  height: 100%;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  background-color: rgba(0, 0, 0, 0.45);
}

.font__M__plus {
  font-family: 'M PLUS 1', sans-serif !important;
}

.font__Rexliafree {
  font-family: 'Rexliafree', sans-serif !important;
}

body {
  margin: 0;
  font-family: 'M PLUS 1', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(180deg, #000926 0%, #00154a 48.96%, #000926 100%),
    linear-gradient(0deg, #fafafb, #fafafb);
  color: #fff;
  font-weight: 400;
}

.card__monster {
  background: url(../imgs/bg_border__item.png) center center no-repeat;
  background-size: contain;
}
.title__shop, .title__shop_card {
  filter: drop-shadow(0 0 0.75rem #2ca0ffcc);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-content {
  margin-top: 70px;
  min-height: calc(100vh - 185px);
  position: relative;
}

.password input {
  background: #fff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #fff;
}

::placeholder {
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6) !important;
  opacity: 1;
  /* Firefox */
}

.cancel {
  font-weight: bold;
  padding: 10px;
  color: #fff;
  width: 50%;
  height: 48px;
  background: transparent !important;
  border: none
}

.confirm{
  font-weight: bold;
  padding: 10px;
  color: #000;
  background-color: #FAA52C !important;
  width: 50%;
  height: 48px;
  border-radius: 4px;
}
.logout{
  opacity: 0.9;
  background: linear-gradient(0deg, #041e4f, #041e4f), linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
}
