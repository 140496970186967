.ant-btn-primary {
  color: rgb(255, 255, 255);
  text-shadow: rgba(0, 0, 0, 0.12) 0px -1px 0px;
  box-shadow: rgba(0, 0, 0, 0.043) 0px 2px 0px;
  border-color: #1890ff;
  background: #1890ff;
}

.ant-btn {
  font-family: 'Rexliafree', sans-serif;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-card .ant-card-body {
  height: 100%;
}

.ant-drawer .ant-drawer-content {
  background: #051e4e;
}

.ant-drawer .ant-drawer-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.ant-drawer .ant-drawer-title {
  color: #fff;
}

.ant-drawer .ant-drawer-close {
  color: #fff;
}

.ant-picker {
  background: #00113d;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.3);
}

.border__grey {
  border-color: rgba(255, 255, 255, 0.3);
}

.ant-picker .ant-picker-separator {
  color: #fff;
  height: 21px;
}

.ant-picker .ant-picker-input > input {
  color: #fff;
}
.ant-picker .ant-picker-suffix {
  color: #fff;
}
.dateRanking .ant-picker-input > input {
  color: #000;
}

.dateRanking .ant-picker-suffix {
  color: #000;
}

.ant-table-wrapper .ant-table {
  background: #0d2758;
  border: 1px solid #2ca0ffcc;
  border-radius: 8px;
  box-shadow: 0 0 6px 0 #2ca0ffcc;
  color: #fff;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  background: #1890ff;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.ant-table-wrapper .ant-table-thead > tr > th {
  font-family: 'Rexliafree', sans-serif;
  font-weight: 400;
}

.ant-table-wrapper .ant-table-tbody > tr > th,
.ant-table-wrapper .ant-table-tbody > tr > td {
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
.ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  display: none;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover,
td.ant-table-column-sort {
  background: rgba(44, 160, 255, 0.1) !important;
}

.scrollbar_container ::-webkit-scrollbar {
  height: 6px;
  display: block;
}

.scrollbar_container ::-webkit-scrollbar-track {
  border-radius: 2px;
  width: 2px !important;
}

.scrollbar_container ::-webkit-scrollbar-thumb {
  background: #1890ff;
  border-radius: 8px;
  width: 2px !important;
}

.ant-pagination {
  color: #fff;
  display: flex;
}

.ant-pagination .ant-pagination-prev button,
.ant-pagination .ant-pagination-next button {
  color: #fff;
}

.ant-pagination .ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination .ant-pagination-disabled:hover .ant-pagination-item-link {
  color: rgba(255, 255, 255, 0.25);
}

.ant-pagination.ant-pagination-simple .ant-pagination-simple-pager input {
  background-color: transparent;
  border: 1px solid #1890ff;
}

.ant-pagination.ant-pagination-simple .ant-pagination-prev,
.ant-pagination.ant-pagination-simple .ant-pagination-next {
  background: #1890ff;
  height: 32px;
  width: 32px;
}

.ant-pagination.ant-pagination-simple .ant-pagination-simple-pager {
  height: 32px;
  margin: 0 20px;
}

.ant-pagination.ant-pagination-simple .ant-pagination-simple-pager input {
  background-color: transparent;
}

.pagination-item {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  background-color: #1890ff;
  border-radius: 3px;
  width: 32px;
  height: 32px;
}

.pagination-item:hover img {
  transform: scale(1.04);
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.5s;
}

.ant-pagination .ant-pagination-disabled,
.ant-pagination .ant-pagination-disabled:hover img {
  transform: scale(1) !important;
}

[class^='ant-pagination'] [class^='ant-pagination'],
[class*=' ant-pagination'] [class^='ant-pagination'],
[class^='ant-pagination'] [class*=' ant-pagination'],
[class*=' ant-pagination'] [class*=' ant-pagination'] {
  font-size: 18px;
  font-weight: 500;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #00113f;
  border: 0px solid #00113f;
  color: #fff;
  font-weight: 700;
  height: 100%;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #fff;
  font-weight: 700;
}

.ant-modal .ant-select-single.ant-select-open .ant-select-selection-item {
  color: #fff;
  font-weight: 300;
}

.ant-select .ant-select-selection-placeholder {
  color: rgb(177, 178, 182);
  font-family: 'M PLUS 1', sans-serif;
  font-weight: 700;
}

.pagination__common {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pagination__common .ant-pagination {
  font-family: 'M PLUS 1', sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination__common .ant-pagination .ant-pagination-item a {
  color: #fff;
  font-size: 14px;
  font-family: 'M PLUS 1', sans-serif !important;
}

.ant-select-selection-item {
  font-family: 'M PLUS 1', sans-serif !important;
}

.pagination__common .ant-pagination .ant-pagination-item-active {
  background: #00113f;
  padding: 6px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.pagination__common
  .ant-pagination
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
.pagination__common
  .ant-pagination
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  color: rgba(255, 255, 255, 0.25);
}

.ant-select-single.ant-select-sm .ant-select-selector {
  background: #0d2758;
  border: 1px solid #1890ff;
  color: #fff;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-pagination.ant-pagination-mini .ant-pagination-options {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-select-single.ant-select-sm.ant-select-open .ant-select-selection-item {
  color: #fff;
}

.ant-select-dropdown {
  background-color: #0d2758;
  border: 1px solid #1890ff;
  color: #fff;
}

.ant-select-dropdown .ant-select-item {
  color: #fff;
  font-family: 'M PLUS 1', sans-serif !important;
}
.ant-select-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.ant-select-dropdown .ant-select-item:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  font-weight: 500;
}

.ant-pagination.ant-pagination-mini .ant-pagination-options-quick-jumper {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}

.ant-pagination .ant-pagination-options-quick-jumper input {
  border: 1px solid #1890ff;
  background-color: transparent;
  color: #fff;
  height: 32px !important;
}

.ant-select .ant-select-arrow {
  color: #fff;
}

.ant-form-item-label > label {
  color: #fff !important;
  height: 100% !important;
}

.ant-table-wrapper .ant-table-tbody > tr:last-child > th,
.ant-table-wrapper .ant-table-tbody > tr:last-child > td {
  border: none;
}

.ant-empty-normal .ant-empty-description {
  color: rgba(255, 255, 255, 0.5);
}

.ant-pagination.ant-pagination-mini:not(.ant-pagination-disabled)
  .ant-pagination-item:not(.ant-pagination-item-active) {
  width: 32px;
  height: 32px;
}

.ant-btn.ant-btn-icon-only {
  width: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-modal .ant-modal-content {
  background: linear-gradient(180deg, #243fa1 0%, #1b2f78 47.4%, #1b2f78 100%);
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  color: #fff;
  font-family: 'Rexliafree', sans-serif;
}

.ant-modal .ant-modal-content {
  padding: 0;
  background: linear-gradient(180deg, #243fa1 0%, #1b2f78 47.4%, #1b2f78 100%);
}

.ant-modal .ant-modal-header {
  color: #fff;
  background: linear-gradient(180deg, #305fb7 0%, #4586d7 100%);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.ant-modal .ant-modal-title {
  color: #fff;
  font-family: 'Rexliafree', sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.ant-modal .ant-modal-close {
  color: #fff;
}

.ant-modal .ant-modal-close:hover {
  color: #020f38;
}

.ant-modal-body {
  background: linear-gradient(180deg, #243fa1 0%, #1b2f78 47.4%, #1b2f78 100%);
  color: #fff;
  padding: 20px 24px !important;
  border-radius: 8px;
}

.ant-modal .ant-modal-footer {
  padding: 20px 24px 24px 20px;
  margin-top: 0;
}

.ant-modal-body .ant-input {
  background: #fff;
  color: #000;
}

.ant-modal-body ::placeholder {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6) !important;
  opacity: 1;
  /* Firefox */
}

.ant-modal .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #fff;
  color: #000;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
}

.ant-modal .ant-select {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  height: 100%;
}

.ant-modal .ant-select .ant-select-arrow {
  color: #000;
}

.ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item
  .ant-upload-list-item-actions
  .ant-upload-list-item-action:focus-visible,
.ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item
  .ant-upload-list-item-actions.picture
  .ant-upload-list-item-action {
  background: transparent;
}

.ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item
  .ant-upload-list-item-actions
  .anticon {
  color: #ff4d4f;
}

.ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item
  .ant-upload-list-item-actions:hover
  .anticon {
  color: rgba(255, 77, 79, 0.7);
}

.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture
  .ant-upload-list-item,
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item,
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item {
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.ant-card {
  background: #0d2758;
  border: 1px solid #2ca0ffcc;
  box-shadow: 0 0 6px 0 #2ca0ffcc;
  color: #fff;
  border-radius: 4px;
}

.ant-table-wrapper
  .ant-table-tbody
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell {
  background: rgba(44, 160, 255, 0.1);
}

.ant-radio-wrapper .ant-radio {
  align-self: baseline;
}

.ant-radio-wrapper .ant-radio-inner {
  width: 18px;
  height: 18px;
}

.ant-card .ant-card-head {
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.ant-card .ant-card-extra {
  color: #fff;
}

.equal-height-row-GameDashboard {
  display: flex;
  flex-wrap: wrap;
}

.equal-height-col-GameDashboard {
  display: flex;
  flex-direction: column;
  min-height: 700px;
  /* max-height: 750px;  */
}
.equal-height-col-GameDashboard .ant-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 700px;
  max-height: 750px;
}

.equal-height-col-GameDashboard .ant-card-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 700px;
  max-height: 750px;
}
/*  */
.menuLength {
  white-space: normal !important;
  height: auto !important;
}

.ant-dropdown .ant-dropdown-menu,
.ant-dropdown-menu-submenu .ant-dropdown-menu {
  background: rgba(13, 39, 88, 1);
  border: 1px solid rgba(24, 144, 255, 1);
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-title-content {
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title-active,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-selected,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title-selected {
  background-color: rgba(0, 0, 0, 0.3);
}

.ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  border-color: #1677ff !important;
}

.box__filter .ant-select-selector {
  background-color: rgb(13, 39, 88) !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
  border-radius: 8px !important;
  height: auto !important;
}

.box__filter .ant-select-multiple .ant-select-selection-item {
  background: #03113d;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
  border-radius: 8px !important;
}

.box__filter .ant-select-multiple .ant-select-selection-item-remove {
  color: #fff;
}

.managementCollaborationNFT .ant-select .ant-select-arrow {
  margin-top: 0;
}

.ant-popover .ant-popover-inner {
  background-color: #0d2758;
  color: #fff;
}
.ant-popover .ant-popover-title {
  color: #fff;
}
.ant-popover .ant-popover-inner-content {
  color: #fff;
}
.ant-modal .ant-input-affix-wrapper {
  background: #fff;
}
.ant-modal .ant-input-number .ant-input-number-input {
  background: #fff;
}

.ant-input {
  background: #00113f;
  color: #fff;
  font-family: 'M PLUS 1';
}

.ant-input-affix-wrapper {
  background: #00113f;
  border: 1px solid #00113f;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #00113f;
  border: 0px solid #00113f;
  color: #fff;
  font-weight: 700;
  border-radius: 8px;
}
.addUserList .ant-select-selector {
  background: #0d2758 !important;
  border: 0px solid #fff;
  color: #fff !important;
  height: 44px !important;
  font-weight: 700;
  border-radius: 8px;
}

.addUserList .ant-select-arrow {
  margin-top: 2px;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #fff;
  font-weight: 700;
}

.ant-select .ant-select-selection-placeholder {
  color: rgb(177, 178, 182);
  font-family: 'M PLUS 1', sans-serif;
  font-weight: 700;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  height: 100%;
}
#ChangeLanguage
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
#ChangeLanguage
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  font-weight: 700;
  height: 100%;
}
#ChangeLanguage .ant-select-selector {
  border-radius: 8px;
}
#FormSendMail .ant-input-affix-wrapper .ant-input-suffix {
  margin: 0;
}

.ant-select-single.ant-select-sm:not(
    .ant-select-customize-input
  ).ant-select-show-arrow
  .ant-select-selection-search {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-message .ant-message-notice-wrapper .ant-message-notice-content {
  background: #00113f;
}
.ant-checkbox-disabled,
.ant-checkbox-disabled .ant-checkbox-input {
  opacity: 0.5;
}
.default-row {
  background-color: #0d2758 !important; /* Yellow background for highlighted rows */
}
.highlight-row {
  background-color: rgba(
    47,
    144,
    255,
    0.2
  ) !important; /* Yellow background for highlighted rows */
}
.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder:hover > th,
.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder:hover > td,
.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder {
  background: #0d2758;
}
.ant-popover .ant-popover-arrow:before {
  background: #020f38;
}

.ant-image .ant-image-mask {
  border-radius: 50%;
}
.btnOTPPhone[disabled] {
  cursor: pointer;
  border-color: #d9d9d9;
  background-color: #fb923c;
  box-shadow: none;
}

.btnVerify[disabled] {
  cursor: not-allowed;
  border-color: #d9d9d9;
  background-color: #1890ff;
  opacity: 0.5;
  box-shadow: none;
  color: #fff;
}
.ReadOnly .ant-input-affix-wrapper > input.ant-input {
  background: #283e79;
}
.ReadOnly .ant-input-affix-wrapper {
  background: #283e79;
  border: 1px solid #283e79;
}
.ContainerGiftDetail {
  background: #1890ff;
  border-radius: 8px;
  box-shadow: 0px 0px 6px 0px rgba(44, 160, 255, 0.8);
  margin-bottom: 5%;
}
.boxAddressGift {
  display: grid;

  grid-template-columns: 1fr 1fr;
  gap: 5%; /* Khoảng cách giữa hai cột */
}
.addressGiftDetail {
  background: #0d2758;
  border-radius: 8px;
  box-shadow: 0px 0px 6px 0px rgba(44, 160, 255, 0.8);
}
.addressGiftDetailTitle {
  background: #1890ff;
  font-family: Rexliafree;
  padding: 16px;
  display: flex;
  justify-content: center;
  border-radius: 8px 8px 0 0;
}
.addressGiftDetailContent {
  max-height: 291px;
  overflow-y: auto;
  padding: 16px;
}
.addressGiftDetailContent::-webkit-scrollbar {
  width: 10px;
}

.addressGiftDetailContent::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
}

.addressGiftDetailContent::-webkit-scrollbar-thumb {
  background-color: #40a9ff;
  border-radius: 10px;
}

.addressGiftDetailContent::-webkit-scrollbar-thumb:hover {
  background-color: #1e90ff;
}
.guildInfo::-webkit-scrollbar {
  width: 10px;
}

.guildInfo::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
}

.guildInfo::-webkit-scrollbar-thumb {
  background-color: #40a9ff;
  border-radius: 10px;
}

.guildInfo::-webkit-scrollbar-thumb:hover {
  background-color: #1e90ff;
}

.gameMenu::-webkit-scrollbar {
  width: 10px;
}

.gameMenu::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
}

.gameMenu::-webkit-scrollbar-thumb {
  background-color: #40a9ff;
  border-radius: 10px;
}

.gameMenu::-webkit-scrollbar-thumb:hover {
  background-color: #1e90ff;
}

.achievement::-webkit-scrollbar {
  width: 10px;
}

.achievement::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
}

.achievement::-webkit-scrollbar-thumb {
  background-color: #40a9ff;
  border-radius: 10px;
}

.achievement::-webkit-scrollbar-thumb:hover {
  background-color: #1e90ff;
}

.gameFilter .ant-card-body {
  padding: 0;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns */
  grid-auto-rows: minmax(50px, auto); /* Automatic row height */
}

.grid-item {
  display: flex;
  align-items: center; /* Center content inside grid item */
  justify-content: flex-start; /* Center content vertically */
  height: 100%; /* Ensure the height is consistent */
  margin: 0 0 0 10%;
}
.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:active {
  background-color: #0d2758;
}
.rankingAchievement > .ant-menu .ant-menu-submenu-arrow {
  display: none;
}
.ant-progress .ant-progress-text {
  color: #fff;
}
.pageSector > .ant-pagination-options > div > div {
  background: #00113f !important;
  border: 0px solid #00113f !important;
  color: #fff !important;
}
